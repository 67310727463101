import * as firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyDzI1G_LR6rvHJZgv0ggqoA8B8IUW8HPqg",
  authDomain: "cardproject-f8784.firebaseapp.com",
  databaseURL: "https://cardproject-f8784.firebaseio.com",
  projectId: "cardproject-f8784",
  storageBucket: "",
  messagingSenderId: "805390501156",
  appId: "1:805390501156:web:782c7ee30d4facdf"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
